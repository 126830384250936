import { type FC, useState } from 'react';
import { motion } from 'framer-motion';

const WaitlistForm: FC = () => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState<'idle' | 'sending' | 'success' | 'error'>('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus('sending');
    setErrorMessage('');

    try {
      const response = await fetch('https://services.leadconnectorhq.com/hooks/BldMYEOj8ZFWMPkwj4xb/webhook-trigger/86fa8fea-10a7-47d0-ada1-feaf20729bcd', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          source: 'Pretty Brilliant Waitlist',
          timestamp: new Date().toISOString(),
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit form');
      }

      setStatus('success');
      setEmail('');
    } catch (error) {
      console.error('Form Submission Error:', error);
      setStatus('error');
      setErrorMessage(error instanceof Error ? error.message : 'An unexpected error occurred');
    }
  };

  return (
    <motion.form 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, delay: 0.6 }}
      onSubmit={handleSubmit} 
      className="space-y-4"
    >
      <div className="relative group">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email address"
          className="w-full px-4 py-3 rounded-lg gradient-border bg-navy/20 text-pearl placeholder-pearl/40 focus:outline-none focus:ring-2 focus:ring-rose-gold/50 transition-all duration-300 group-hover:bg-pearl/5 text-sm"
          required
          disabled={status === 'sending'}
        />
        <div className="absolute inset-0 rounded-lg bg-gradient-to-r from-rose-gold/10 to-gold/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none" />
      </div>
      <motion.button
        type="submit"
        className="metallic-sheen w-full px-4 py-3 bg-gradient-to-r from-rose-gold to-gold text-aubergine rounded-lg font-medium tracking-wide transition-all duration-300 hover:from-rose-gold/90 hover:to-gold/90 hover:shadow-lg text-sm uppercase tracking-wider disabled:opacity-50 disabled:cursor-not-allowed"
        whileHover={{ scale: status === 'sending' ? 1 : 1.01 }}
        whileTap={{ scale: status === 'sending' ? 1 : 0.99 }}
        disabled={status === 'sending'}
      >
        {status === 'sending' ? 'Joining...' : 'Join The Waitlist'}
      </motion.button>
      {status === 'success' && (
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-center text-rose-gold/90 text-sm"
        >
          Thank you for joining! We'll be in touch soon.
        </motion.p>
      )}
      {status === 'error' && (
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-center text-rose-gold/90 text-sm"
        >
          {errorMessage || 'Oops! Something went wrong. Please try again.'}
        </motion.p>
      )}
      <div className="space-y-3 pt-2">
        <p className="text-center text-pearl/40 text-xs">
          Limited spots available. By joining, you agree to our{' '}
          <a href="/terms" className="text-rose-gold hover:text-gold transition-colors duration-300">Terms</a>{' '}
          and{' '}
          <a href="/privacy" className="text-rose-gold hover:text-gold transition-colors duration-300">Privacy Policy</a>.
        </p>
        <p className="text-center text-pearl/30 text-xs">
          © 2024 PrettyBrilliant. All rights reserved.
        </p>
      </div>
    </motion.form>
  );
};

export default WaitlistForm;