import { type FC } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const MainContent: FC = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const paragraphs = [
    {
      text: "Join an elite circle of beauty industry leaders who receive our proprietary market intelligence, ensuring your brand stays ahead.",
      delay: 0
    },
    {
      text: "Our insights have helped shape the strategies of the top 1% of beauty e-commerce brands, delivering unparalleled growth.",
      delay: 0.2
    },
    {
      text: "With the beauty sector's rapid expansion, we occasionally open our ranks to visionary new members. Secure your position now.",
      delay: 0.4
    }
  ];

  return (
    <motion.div 
      ref={ref} 
      className="space-y-3"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
    >
      {paragraphs.map((p, index) => (
        <motion.p
          key={index}
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.6, delay: p.delay }}
          className="text-sm sm:text-base leading-relaxed text-pearl/90 font-light"
        >
          {p.text}
        </motion.p>
      ))}
    </motion.div>
  );
};

export default MainContent;