import { type FC, useRef, useEffect, useState } from 'react';
import { motion } from 'framer-motion';

interface ProfileCardProps {
  containerId: string;
}

const ProfileCard: FC<ProfileCardProps> = ({ containerId }) => {
  const [containerHeight, setContainerHeight] = useState<number | null>(null);
  const observerRef = useRef<ResizeObserver | null>(null);

  useEffect(() => {
    const container = document.getElementById(containerId);
    
    if (container) {
      const updateHeight = () => {
        setContainerHeight(container.offsetHeight);
      };

      updateHeight();
      observerRef.current = new ResizeObserver(updateHeight);
      observerRef.current.observe(container);

      return () => {
        if (observerRef.current) {
          observerRef.current.disconnect();
        }
      };
    }
  }, [containerId]);

  if (!containerHeight) return null;

  return (
    <motion.div 
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.6 }}
      style={{ height: `${containerHeight}px` }}
      className="relative w-full rounded-xl overflow-hidden"
    >
      <div className="absolute inset-0">
        <div className="relative w-full h-full">
          <motion.img
            initial={{ scale: 1.1 }}
            animate={{ scale: 1 }}
            transition={{ duration: 1.2 }}
            src="https://i.postimg.cc/XNCwhHgt/image-19-2-1-1.png"
            alt="Professional beauty industry expert"
            className="absolute inset-0 w-full h-full object-cover object-center"
            loading="eager"
          />
        </div>
      </div>
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.3 }}
        className="absolute bottom-4 right-4 bg-white/10 backdrop-blur-xl text-white p-4 rounded-lg border border-white/20 z-10"
      >
        <h3 className="text-xl font-serif mb-2">Katie Welch</h3>
        <p className="text-sm opacity-90">
          CMO @ Rare Beauty &<br />
          <span className="text-rose-gold/90 font-medium">Pretty Brilliant Member</span>
        </p>
      </motion.div>
    </motion.div>
  );
};

export default ProfileCard;