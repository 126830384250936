import { type FC } from 'react';
import { motion } from 'framer-motion';

const Header: FC = () => {
  return (
    <header className="mb-4">
      <motion.span 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="text-rose-gold uppercase tracking-[0.2em] text-xs mb-3 block text-shadow-glow"
      >
        Limited Access
      </motion.span>
      <motion.h1 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.1 }}
        className="text-2xl sm:text-3xl lg:text-4xl font-serif leading-tight tracking-tight"
      >
        <span className="text-pearl">Discover Beauty's</span>
        <br />
        <span className="text-transparent bg-clip-text bg-gradient-to-r from-rose-gold via-gold to-rose-gold bg-300% animate-gradient">
          Most Exclusive Insights
        </span>
      </motion.h1>
    </header>
  );
};

export default Header;