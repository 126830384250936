import { type FC } from 'react';
import BrandBar from './BrandBar';

const Navigation: FC = () => {
  return (
    <div className="w-full">
      <BrandBar />
    </div>
  );
};

export default Navigation;