import { type FC } from 'react';
import Header from './components/Header';
import WaitlistForm from './components/WaitlistForm';
import Footer from './components/Footer';
import BrandBar from './components/BrandBar';
import MainContent from './components/MainContent';
import ProfileCard from './components/ProfileCard';
import Navigation from './components/Navigation';

const App: FC = () => {
  return (
    <div className="h-screen overflow-hidden bg-navy flex flex-col">
      <Navigation />
      <main className="flex-1 flex items-center">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-5xl">
          <div className="gradient-mesh bg-navy/20 backdrop-blur-sm rounded-2xl border border-rose-gold/10 p-6 sm:p-8 lg:p-10">
            <div className="grid lg:grid-cols-2 gap-8">
              <div id="content-container" className="space-y-6">
                <Header />
                <MainContent />
                <WaitlistForm />
              </div>
              <div className="hidden lg:block">
                <div className="sticky top-24">
                  <ProfileCard containerId="content-container" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <div className="backdrop-blur-sm">
        <BrandBar position="bottom" />
      </div>
    </div>
  );
};

export default App;